<template>
  <Modal v-model="showModal" :mask-closable="false" width="600px">
    <div slot="header">编辑媒体分组信息</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="80">
      <FormItem prop="parentId" label="上级分组">
        <Select v-model="form.parentId">
          <Option :value="0">无</Option>
          <template v-for="(item, idx) in groups">
            <Option :value="item.id" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="name" label="分组名称">
        <Input ref="name" type="text" v-model="form.name" placeholder="请输入名称">
        </Input>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" :loading="loading" @click="ok">保存</Button>
    </div>
  </Modal>
</template>
<script>
// import { mapState } from 'vuex'
export default {
  name: 'MediaGroupEdit',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
    groups: {
      type: Array,
      default(){ return [];}
    }
  },
  data () {
    return {
      showModal: this.value,
      loading: false,
      isAdd: false,
      form: {
        id: 0,
        name: '',
        parentId: 0,
      },
      rules: {
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' },
          { type: 'string', min: 2, max: 20, message: '名称长度必须2 - 20个字符', trigger: 'blur' }
        ],
      },
    }
  },
  computed: {
    // ...mapState('group', ['groups', 'selectedNode', 'selectedGroups', 'rootTitle']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.isAdd = this.item.isAdd;
        if(!this.isAdd){
          this.form.id = this.item.data.id;
          this.form.name = this.item.data.name;
          this.form.parentId = this.item.data.parentId;
        }else{
          this.form.id = 0;
          this.form.name = '';
          this.form.parentId = this.item.data.parentId;
        }
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  methods: {
    ok: async function () {
      let result = await this.$refs.form.validate();
      if(!result){
        return;
      }
      let req;
      if(this.isAdd){
        req = this.$axios.post(`//${this.domains.trans}/media/group`, this.form);
      }else{
        req = this.$axios.put(`//${this.domains.trans}/media/group/${this.form.id}`, this.form)
      }
      req.then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
          return;
        }
        this.$Message.info('保存成功');
        this.$emit('saved');
        setTimeout(() => {
          this.showModal = false;
        }, 200);
      });
    },
    cancel () {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.demo-drawer-footer{
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form{
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly{
  background: #eee
}
</style>