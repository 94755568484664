<template>
<div class="media-container">
  <div class="media-slider">
    <div class="slider-header">
      <div class="header-title" @click="getGroups">多媒体分组</div>
      <Button v-if="funCodes('mga')" type="primary" size="small" class="header-btn" :loading="loading" @click="addGroup">新增</Button>
      <Button v-if="funCodes('mge')" type="primary" size="small" class="header-btn" :loading="loading" @click="editGroup">修改</Button>
      <Button v-if="funCodes('mgd')" type="error" size="small" class="header-btn" :loading="loading" @click="deleteGroup">删除</Button>
    </div>
    <div class="slider-content">
      <Tree ref="tree" :data="treeData" @on-select-change="nodeChange"></Tree>
    </div>
  </div>
  <div class="media-content" ref="table">
    <u-table :data="medias" use-virtual style="width: 100%;" row-key="id" border stripe :height="tabHeight" default-expand-all>
      <u-table-column prop="confirm" label="状态" sortable width="120" show-overflow-tooltip>
        <template slot-scope="params">
          {{getConfirmState(params.row)}}
        </template>
      </u-table-column>
      <u-table-column prop="name" label="名称" sortable>
        <template slot-scope="params">
          {{params.row.name}}
        </template>
      </u-table-column>
      <u-table-column prop="type" label="类型" sortable width="80">
        <template slot-scope="params">
          {{mediaTypes[params.row.type]}}
        </template>
      </u-table-column>
      <u-table-column prop="width" label="宽度" sortable width="80">
        <template slot-scope="params">
          {{params.row.width}}
        </template>
      </u-table-column>
      <u-table-column prop="height" label="高度" sortable width="80">
        <template slot-scope="params">
          {{params.row.height}}
        </template>
      </u-table-column>
      <u-table-column prop="ext" label="扩展名" sortable>
        <template slot-scope="params">
          {{params.row.ext}}
        </template>
      </u-table-column>
      <u-table-column prop="mime" label="MIME类型" sortable width="120">
        <template slot-scope="params">
          {{params.row.mime}}
        </template>
      </u-table-column>
      <u-table-column prop="size" label="文件大小" sortable width="120">
        <template slot-scope="params">
          {{params.row.size}}
        </template>
      </u-table-column>
      <u-table-column prop="time" label="播放时长" sortable width="120">
        <template slot-scope="params">
          {{params.row.time}}
        </template>
      </u-table-column>
      <u-table-column prop="remark" label="描述">
        <template slot-scope="params">
          {{params.row.remark}}
        </template>
      </u-table-column>
      <u-table-column width="175">
        <template slot="header" slot-scope="scope">
          <Button v-if="funCodes('mda')" style="margin-right: 5px" size="small" type="primary" @click="handleAdd(scope)">新增</Button>
        </template>
        <template slot-scope="scope">
          <Button v-if="funCodes('mdap') && !scope.row.confirm" size="small" type="success" style="margin-right: 5px" @click="handleConfirm(scope)">审核</Button>
          <Button v-if="funCodes('mde')" style="margin-right: 5px" size="small" type="primary" @click="handleEdit(scope)">编辑</Button>
          <Button v-if="funCodes('mdd')" type="error" size="small" @click="handleDelete(scope)">删除</Button>
        </template>
      </u-table-column>
    </u-table>
  </div>
  <ModalGroupEdit v-model="showGroupModal" :item="editItem" :groups="groups" @saved="getGroups" />
  <ModalMediaEdit v-model="showEditModal" :item="editItem" :groups="groups" @saved="getMedias" />
  <ModalConfirmEdit v-model="showConfirmModal" :item="editItem" :groups="groups" @saved="getMedias" />
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalGroupEdit from './ModalGroupEdit'
import ModalMediaEdit from './ModalMediaEdit'
import ModalConfirmEdit from './ModalConfirmEdit'
export default {
  name: 'MediaIndex',
  components:{
    ModalGroupEdit,
    ModalMediaEdit,
    ModalConfirmEdit,
  },
  props: {
  },
  data () {
    return {
      tabHeight: 300,
      treeData: [],
      groups: [],
      loading: false,
      showGroupModal: false,
      showConfirmModal: false,
      showEditModal: false,
      selectedNode: {id: 0},
      medias: [],
      editItem: {isAdd: false, index: -1, data: {}},
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['mediaTypes']),
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
  },
  mounted: function(){
    setTimeout(this.setTableHeight, 200);
    this.getGroups();
  },
  methods: {
    getConfirmState: function(row){
      if(!row.confirm)return '未审核';
      if(!row.pass)return `审核未通过：${row.reason}`;
      return '审核通过';
    },
    handleConfirm: function(params){
      this.editItem = {data: params.row};
      this.showConfirmModal = true;
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table.clientHeight;
    },
    getGroups: function(){
      this.$axios.get(`//${this.domains.trans}/media/group`).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'groups', res.data);
        let tree = [];
        this.initTreeData(tree, res.data, 0);
        this.$set(this, 'treeData', tree);
        if(tree.length > 0){
          this.nodeChange(tree, tree[0])
        }
      });
    },
    deleteGroup: function(){
      let nodes = this.$refs.tree.getSelectedNodes();
      if(nodes.length == 0){
        this.$Message.warning('请选择要删除的分组');
        return;
      }
      let node = nodes[0];
      if(node.children.length > 0){
        this.$Message.warning('有子分组，不能删除');
        return;
      }
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除分组[${node.title}]吗?`,
        onOk: async () => {
          this.$axios.delete(`//${this.domains.trans}/media/group/${node.id}`).then(res => {
            if(res.code !== 0){
              // this.$Message.error(res.data);
              return;
            }
            this.$Message.info('删除成功');
            this.getGroups();
          });
        }
      });
    },
    initTreeData: function(children, list, parentId){
      let ps = list.filter(p => p.parentId == parentId);
      if(parentId <= 0){
        children.push({id: -1, title: '未审核', data: {}, children: [], expand: true});
      }
      for(let p of ps){
        let node = {id: p.id, title: p.name, data: p, children: [], expand: true}
        this.initTreeData(node.children, list, node.id);
        children.push(node);
      }
    },
    addGroup: function(){
      let nodes = this.$refs.tree.getSelectedNodes();
      let parentId = 0;
      if(nodes.length > 0){
        parentId = nodes[0].id;
      }
      this.editItem = {isAdd: true, data: {parentId: parentId}};
      this.showGroupModal = true;
    },
    editGroup: function(){
      let nodes = this.$refs.tree.getSelectedNodes();
      if(nodes.length == 0){
        this.$Message.warning('请选择要编辑的分组');
        return;
      }
      this.editItem = {isAdd: false, data: nodes[0].data};
      this.showGroupModal = true;
    },
    nodeChange: function(nodes, node){
      // console.log('node change', node);
      if(this.selectedNode.id == node.id)return;
      this.selectedNode = node;
      this.getMedias();
    },
    getMedias: function(){
      if(this.selectedNode.id == -1){
        this.$axios.post(`//${this.domains.trans}/media/operation/GetNotConfirm`, {}).then(res => {
          if(res.code != 0){
            // this.$Message.error(res.data);
            return;
          }
          this.$set(this, 'medias', res.data);
        });
        return;
      }
      let groupId = 0;
      if(this.selectedNode.id > 0){
        groupId = this.selectedNode.id;
      }
      if(groupId == 0){
        this.$set(this, 'medias', []);
        return;
      }
      this.$axios.get(`//${this.domains.trans}/media/list/${groupId}`).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'medias', res.data);
      });
    },
    handleAdd: function(){
      let groupId = 0;
      if(this.selectedNode.id > 0){
        groupId = this.selectedNode.id;
      }
      this.editItem = {isAdd: true, data: {groupId: groupId}};
      this.showEditModal = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.showEditModal = true;
    },
    videoLoaded: function(v){
      v.srcElement.play();
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除节目[${params.row.name}]吗?`,
        onOk: async () => {
          let res = await this.$axios.delete(`//${this.domains.trans}/media/list/${params.row.id}`);
          if(res.code !== 0){
            // this.$Message.error(res.data);
            return;
          }
          this.medias.splice(params.index, 1);
          this.$Message.info('删除成功');
        }
      });
    },
  }
}
</script>
<style scoped>
.media-container{
  /* border: solid 1px red; */
  height: 100%;
  display: flex;
  padding: 10px;
  background-color: white;
}
.media-slider{
  /* border: solid 1px red; */
  width: 350px;
  flex: none;
  display: flex;
  flex-direction: column;
  margin-right: 3px;
}
.slider-header{
  border: solid 1px rgba(218, 218, 218, 0.568);
  border-radius: 6px;
  margin-bottom: 3px;
  height: 40px;
  flex: none;
  display: flex;
  align-items: center;
  background-color: white;
}
.header-title{
  width: 100px;
  flex: auto;
  font-weight: bolder;
  font-size: 20px;
  padding-left: 5px;
  cursor: pointer;
}
.header-btn{
  margin-right: 5px;
}
.slider-content{
  border: solid 1px rgba(218, 218, 218, 0.568);
  border-radius: 6px;
  height: 40px;
  flex: auto;
  overflow: auto;
  background-color: white;
}
.media-content{
  /* border: solid 1px red; */
  width: 400px;
  flex: auto;
}
</style>