<template>
<div class="monitor-main">
  <div class="monitor-content" :style="{paddingTop: `${currentMenuName == 'light' ? 0 : 15}px`, paddingRight: `${currentMenuName == 'light' ? 0 : 15}px`, paddingBottom: `${currentMenuName == 'light' ? 0 : 15}px`}">
    <div class="content-header" v-if="currentMenuName != 'gis' && currentMenuName != 'light'">
      <div :class="['header-item', currentMenu == item.code ? 'header-selected' : '', idx == menus.length - 1 ? 'header-last-item' : '']" v-for="(item, idx) in menus" :key="idx" @click="switchMenu(item)">{{item.name}}</div>
    </div>
    <div class="content-body">
      <template v-for="(item, idx) in menus">
        <component v-if="item.code == currentMenu && item.com" :key="idx" v-bind:is="item.com" :args="item.args"></component>
      </template>
    </div>
  </div>
</div>
</template>
<script>
import MediaAssetIndex from '@/views/media/asset/Index'
// import Working from '../Working'
export default {
  name: 'Home',
  components: {
  },
  props: {
  },
  data(){
    return {
      currentMenuName: 'b',
      currentMenu: 's1',
      menus: [ //菜单
        {code: 's1', name: '媒体库', com: MediaAssetIndex, args: {}},
        // {code: 's2', name: '运营商管理', com: Working, args: {}},
        // {code: 's3', name: '运营合同管理', com: Working, args: {}},
        // {code: 's4', name: '结算管理', com: Working, args: {}},
        // {code: 's5', name: '收费记录', com: Working, args: {}},
      ],
    }
  },
  mounted: function(){
  },
  methods: {
    switchMenu: function(item){
      this.currentMenu = item.code;
    },
    menuSelected: function(name){
      if(this.currentName == name) return;
      this.currentName = name;
      this.$router.push(`/index/${this.sys}/workorder/${name}?code=${this.types[this.sys]}`);
    },
  }
}
</script>
<style scoped>
.monitor-main {
  width: 100%;
  height: 100%;
  display: flex;
}
.monitor-content {
  position: relative;
  /* border: solid 1px red; */
  background-color: #e9edf4;
  width: 720px;
  flex: auto;
  overflow: hidden;
  padding: 15px;
  display: flex;
  flex-direction: column;
}
.content-header {
  /* border: solid 1px red; */
  height: 46px;
  flex: none;
  margin-bottom: 15px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: stretch;
}
.content-body {
  /* border: solid 1px red; */
  height: 400px;
  flex: auto;
  background: transparent;
  overflow: hidden;
  border-radius: 12px;
  /* padding: 10px; */
}
.header-item {
  line-height: 45px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0a1421;
  background-color: #ffffff;
  padding: 0 20px;
  cursor: pointer;
  user-select: none;
  /* border-right: solid 1px red; */
  margin-right: 1px;
}
.header-last-item {
  border-radius: 0 8px 8px 0;
  /* border-right: solid 1px red; */
}
.header-selected {
  color: #ffffff;
  background-color: #007eff;
}
</style>
