<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px">
    <div slot="header">审核媒体信息</div>
    <div class="edit-area">
      <div class="form-area">
        <Form ref="form" :model="form" :rules="rules" :label-width="80">
          <FormItem prop="groupId" label="所属分组">
            <Select v-model="form.groupId" disabled>
              <template v-for="(item, idx) in groups">
                <Option :value="item.id" :key="idx">{{item.name}}</Option>
              </template>
            </Select>
          </FormItem>
          <FormItem prop="name" label="媒体名称">
            <Input type="text" v-model="form.name" placeholder="请输入名称" disabled>
            </Input>
          </FormItem>
          <FormItem prop="remark" label="描述">
            <Input type="textarea" v-model="form.remark" :rows="5" placeholder="请输入名称" disabled>
            </Input>
          </FormItem>
          <FormItem label="媒体信息">
            <div class="media-info">
              <div class="media-info-item">扩展名：{{form.ext}}</div>
              <div class="media-info-item">MIME类型：{{form.mime}}</div>
              <div class="media-info-item">文件大小：{{form.size}} bytes</div>
              <div class="media-info-item" v-if="form.type != 2">原始宽度：{{form.width}} px</div>
              <div class="media-info-item" v-if="form.type != 2">原始高度：{{form.height}} px</div>
              <div class="media-info-item" v-if="form.type != 3">播放时长：{{form.time}} 秒</div>
            </div>
          </FormItem>
        </Form>
      </div>
      <div class="preview-area">
        <div class="preview-content">
          <video v-if="form.type == 1 && url != ''" :src="url" controls="controls" @loadedmetadata="videoLoaded" class="media-player">您的浏览器不支持 video 标签</video>
          <audio v-else-if="form.type == 2 && url != ''" :src="url" controls="controls" @canplay="audioLoaded" class="media-player">您的浏览器不支持 audio 标签</audio>
          <img ref="img" v-else-if="form.type == 3 && url != ''" :src="url" class="media-player" @load="imageLoaded" />
          <span v-else class="media-player">请选择媒体文件</span>
        </div>
      </div>
      <div class="confirm-area">
        <Button type="success" :loading="loading" @click="confirmPass" long style="height: 50px; margin-bottom: 20px;">审核通过</Button>
        <Tag v-for="(item, idx) in reasons" :key="idx" class="reason-item" @click.native="writeReason(item)">{{item}}</Tag>
        <Input type="textarea" v-model="reason" :rows="6" placeholder="不通过的原因" :maxlength="50" show-word-limit style="margin-bottom: 20px;"></Input>
        <Button type="error" :loading="loading" @click="confirmReject" long style="height: 50px;">审核不通过</Button>
      </div>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'ModalMediaEdit',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
    groups: {
      type: Array,
      default(){ return [];}
    }
  },
  data () {
    return {
      reasons: [
        '色情低俗',
        '时政不实信息',
        '违法犯罪',
        '垃圾广告、售卖假货等',
        '造谣传谣',
        '涉嫌欺诈',
        '侮辱谩骂',
        '危险行为',
        '侵犯肖像、隐私、名誉、商标、专利、著作权等',
        '未成年人不当行为',
        '内容不适合未成年观看',
        '其他',
      ],
      showModal: this.value,
      loading: false,
      isAdd: false,
      reason: '',
      form: {
        id: 0,
        name: '',
        groupId: 0,
        type: 0,
        width: 0,
        height: 0,
        ext: '',
        mime: '',
        size: 0,
        time: 0,
        fileId: '',
        remark: '',
      },
      file: null,
      video: null,
      audio: null,
      rules: {
        name: { required: true, type: 'string', max: 20, message: '名称不能为空且长度不能超过20', trigger: 'blur' },
        groupId: { required: true, type: 'number', message: '必须选择分组', trigger: 'on-change' },
      },
    }
  },
  computed: {
    ...mapState('common', ['mediaTypes', 'mediaAccepts']),
    ...mapState('auth', ['token']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.form.id = this.item.data.id;
        this.form.name = this.item.data.name;
        this.form.groupId = this.item.data.groupId;
        this.form.type = this.item.data.type;
        this.form.width = this.item.data.width;
        this.form.height = this.item.data.height;
        this.form.ext = this.item.data.ext;
        this.form.mime = this.item.data.mime;
        this.form.size = this.item.data.size;
        this.form.time = this.item.data.time;
        this.form.remark = this.item.data.remark;
        this.form.fileId = this.item.data.fileId;
        this.url = `//${this.domains.trans}/station/file/media?id=${this.form.fileId}&auth=${this.token}`
      }else{
        if(this.video)this.video.pause();
        if(this.audio)this.audio.pause();
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  methods: {
    confirmPass: function(){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要将节目[${this.form.name}]审核通过吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/media/operation/confirm`, {id: this.form.id, pass: true}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
              return;
            }
            this.$emit('saved');
            this.$Message.info('操作成功');
            setTimeout(() => {
              this.showModal = false;
            }, 800);
          });
        }
      });
    },
    confirmReject: function(){
      let reason = this.reason.trim();
      if(reason.length == 0 || reason.length > 50){
        this.$Message.warning('不通过的原因不能为空且长度不要超过50字符');
        return;
      }
      this.$Modal.confirm({
        title: "提示",
        content: `确定要将节目[${this.form.name}]设为不通过吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/media/operation/confirm`, {id: this.form.id, pass: false, reason}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
              return;
            }
            this.$emit('saved');
            this.$Message.info('操作成功');
            setTimeout(() => {
              this.showModal = false;
            }, 800);
          });
        }
      });
    },
    writeReason: function(item){
      this.reason = item;
    },
    getFileType: function(mime){
      switch(mime){
        case "video/mp4":
        case 'audio/mp4':
        case 'video/mpeg':
          return 1;
        case 'audio/mpeg':
          return 2;
        case 'image/png':
        case 'image/gif':
        case 'image/jpeg':
          return 3;
        default:
          return 0;
      }
    },
    getFileExt: function(name){
      let idx = name.lastIndexOf('.');
      if(idx < 0)return '';
      return name.substr(idx + 1);
    },
    fileChanged: function(e){
      // console.log("fileChanged", a.srcElement.files);
      let file = e.target.files[0];
      let mime = file.type;
      let type = this.getFileType(mime);
      if(type == 0){
        this.$Message.warning('未支持格式，请联系管理员');
        return;
      }
      this.url = window.webkitURL.createObjectURL(file);
      let name = file.name;
      this.form.type = type;
      this.form.mime = mime;
      this.form.size = file.size;
      this.form.ext = this.getFileExt(name);
      this.file = file;
      // console.log("fileChanged", name, mime, type, size, this.url);
    },
    videoLoaded: function(e){
      this.audio = null;
      // console.log("videoLoaded", e);
      // console.log("videoLoaded", a.srcElement.videoWidth, a.srcElement.videoHeight);
      this.video = e.srcElement;
      this.form.width = this.video.videoWidth;
      this.form.height = this.video.videoHeight;
      this.form.time = parseInt(this.video.duration);
      this.video.play();
    },
    audioLoaded: function(e){
      this.video = null;
      // console.log("audioLoaded", e);
      this.audio = e.srcElement;
      this.form.width = 0;
      this.form.height = 0;
      this.form.time = parseInt(this.audio.duration);
      this.audio.play();
    },
    imageLoaded: function(){
      this.video = null;
      this.audio = null;
      // console.log("imageLoaded", e, this.$refs.img.naturalWidth, this.$refs.img.naturalHeight);
      this.form.width = this.$refs.img.naturalWidth;
      this.form.height = this.$refs.img.naturalHeight;
      this.form.time = 0;
    },
    cancel () {
      if(this.video)this.video.pause();
      if(this.audio)this.audio.pause();
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.reason-item{
  cursor: pointer;
}
.edit-area{
  /* border: solid 1px red; */
  height: 500px;
  display: flex;
}
.form-area{
  /* border: solid 1px red; */
  width: 400px;
  flex: none;
  padding: 10px 25px 0 0;
}
.preview-area{
  /* border: solid 1px red; */
  width: 300px;
  flex: none;
  display: flex;
  flex-direction: column;
}
.preview-head{
  border: solid 1px rgba(143, 142, 142, 0.404);
  height: 40px;
  margin-bottom: 10px;
  padding: 10px;
  flex: none;
  display: flex;
  align-items: center;
  border-radius: 4px;
}
.preview-content{
  border: solid 1px rgba(143, 142, 142, 0.404);
  height: 50px;
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.confirm-area{
  /* border: solid 1px red; */
  border: solid 1px rgba(143, 142, 142, 0.404);
  padding: 10px;
  width: 200px;
  flex: auto;
  margin-left: 20px;
}
.file-control{
  margin-left: 10px;
}
.media-player{
  /* border: solid 1px red; */
  max-width: 100%;
  max-height: 100%;
}
.media-info{
  border: solid 1px rgba(143, 142, 142, 0.404);
  height: 235px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.media-info-item{
  border-bottom: solid 1px rgba(143, 142, 142, 0.404);
  padding: 3px 10px;
  height: 39px;
}
</style>